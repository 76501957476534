.PhysicsPrediction {
    margin-left: var(--margin-project-card);
    margin-right: var(--margin-project-card);
}

.PhysicsPrediction .prediction-gif{
  width: 100%;
  height: auto;
  margin-top: var(--margin-titles);
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}