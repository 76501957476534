@import 'fonts.css';

:root {
  --main-border-radius: 0.8vw;
  --main-border-style: solid;
  --main-border-color: #b88e8e;
  --main-border-width: 0.2vw;
  --text-color:  #f1dada;
  --background-color:  #2f1e29;
  --description-background-color: ;
  --description-overlay-color: rgba(0, 0, 0, 0.9);
  --description-text-size: 1.3vw;
  --about-text-size: 1.1vw;
  --box-shadow-default: 0 0 0 hsla(349, 100%, 79%, 0.7), inset 0 0 0.5vw rgba(0, 0, 0, 0.3);
  --box-shadow-hovered: 0 0 0 hsla(349, 100%, 71%, 0.7), inset 0 0 1.5vw rgba(0, 0, 0, 0.5);
  --hovered-color-links: #c94e5e;
  --linear-gradient-background: linear-gradient(145deg, hsl(312, 22%, 25%), hsl(312, 22%, 15%));
  --quote-size: 1.1vw;
  --quote-font-style: italic;
  --quote-alignment: center;
  --time-transition: 0.3s;
  --scale-transition: 1.1;
  --time-project-card-transition: 0.5s;
  --small-border-radius: 0.6vw;
  --margin-text: 2vw;
  --margin-titles: 1vw;
  --margin-small-titles: 0.5vw;
  --margin-project-card: 10%;
}

body {
  margin: 0vh 15vw;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: suse;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;

  & a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 2vw;
    font-weight: bold;
    &:hover{
      color:var(--hovered-color-links);
    }
  }
}

#home, #projects, #about, #skills, #contact {
  scroll-margin-top: 6vw; 
}

html {
  scroll-behavior: smooth;
}

h2 {
  font-size: 2.5vw;
  text-align: left;
  color: var(--text-color);
  margin-left: var(--margin-text);
}

hr {
  border:none;
  background-color: var(--main-border-color);
  height: 0.2vw;
  border-radius: var(--main-border-radius);
  margin-top: 3vw;
  margin-left: var(--margin-text);
  margin-right: var(--margin-text);
}

/* == HEADER == */

header {
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-radius: var(--main-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: var(--main-border-width);
  border-top: 0;
  z-index: 1;
  top: 0;
  position: fixed;
  left: 13vw;
  right: 13vw;
  display: flex;
  justify-content: center;
  background-color: var(--background-color);
  align-items: center; 
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;

  & .header--logo {
    margin-top: 5%;
    width: 10vw;
    height: auto;
    
    .cls-1{
      fill: var(--text-color);
      transition: fill var(--time-transition) ease;
    }

    &:hover{
      .cls-1{
        fill: var(--hovered-color-links);
      }
    }
  }

  & .header--navbar {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%;

    & .centered-part{
      display: flex;
      align-items: center;
      gap: 2vw;
      margin-left: 4vw;
      
      a {
        font-size: 2vw;
        position: relative;
        text-decoration: none;
        transition: transform var(--time-transition) ease, color var(--time-transition) ease;
        
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 0%;
          height: 0.15vw;
          background-color: var(--text-color);
          transition: width var(--time-transition) ease, background-color var(--time-transition) ease;
          transform: translateX(-50%);
          border-radius: var(--main-border-radius);
      }
      
      &:hover {
        transform: scale(var(--scale-transition));
        color: var(--hovered-color-links);
        .cls-1{
          fill: var(--hovered-color-links);
        }
      }
      
      &.active {
        .cls-1{
          fill: var(--hovered-color-links);
        }
        font-weight: bold;
        color: var(--hovered-color-links);
        
        &::after {
          width: 100%;
          background-color: var(--hovered-color-links); 
        }
        
        &:hover {
          transform: scale(var(--scale-transition));
          color: var(--hovered-color-links);
          
          &::after {
            width: 100%;
            background-color: var(--hovered-color-links);
          }
        }
      }
    }
   }
   
   & .small-contact{
    margin-left: auto;
    display: flex;
    align-items: center; 
    margin-right: 4vw;
    gap: 1vw;
    margin-top: 0.5%;

    & .svg {
      width: 2.5vw;
      height: auto;
      color: var(--text-color);
      transition: transform var(--time-transition) ease, color var(--time-transition) ease;
      
      &:hover {
        transform: scale(var(--scale-transition));
        color: var(--hovered-color-links);
      }
      }
    }
  }
}

/* == WHITE SECTION == */
.whitespace {
  margin: 5vw;
}
/* == HOME SECTION == */

.home {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-left: var(--margin-text);
  & .text-container {
    text-align: justify;
    margin-right: 4vw;
    margin-top: 2vw;

    & h1 {  
      font-size: 3vw;
      margin: 0;
    }

    & h2 {
      margin: 0;
      font-size: 2vw;
    }

    & p {
      font-size: var(--about-text-size);
      font-style: italic;
      margin: 0;
    }
  }

  & .image-container {
    top: 0;
    position: relative;
    margin-top: 3.5vw;
    margin-right: 4vw;
    & img {
      width: 20vw;
      height: auto;
      border-style: var(--main-border-style);
      border-color: var(--main-border-color);
      border-radius: var(--main-border-radius);
      border-width: var(--main-border-width);
      box-shadow: var(--box-shadow-default);
    }

  }
}




/* == PROJECTS SECTION == */

.projects{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mainproj-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
}

.mainproj-container {
  width: 45%;
  background: var(--description-background-color);
  box-shadow: var(--box-shadow-default);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-radius: var(--main-border-radius); 
  border-width: var(--main-border-width);
  transition: border-color var(--time-transition) ease, transform var(--time-transition) ease;
    
  & .mainproj-container_image img {
    width: 100%;
    display: block;
    
    border-bottom-left-radius: var(--main-border-radius);
    border-bottom-right-radius: var(--main-border-radius);
    transition: transform var(--time-transition) ease;
  }

  & .mainproj-container_specifics {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: right;
    opacity: 0;
    transition: opacity var(--time-transition) ease, border-color var(--time-transition) ease;
    border-color: var(--main-border-color);
    font-size: var(--description-text-size);
    color: var(--text-color);
  }
  
  & .mainproj-container_specifics ul {
    list-style: none;
    padding: 0;
    margin: 1vw;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 0.5vw;
  }

  & .mainproj-container_specifics li {   
    border-style: var(--main-border-style);
    border-color: var(--main-border-color);
    border-radius: var(--small-border-radius);
    border-width: var(--main-border-width);
    background: var(--background-color);
    padding: 0.3vw;
    transition: border-color var(--time-transition) ease;
  }

  & .mainproj-container_title {
    margin-top: 2%;
    font-weight: bold;
    font-size: 1.5vw;
    color: var(--text-color);
    text-align: left;
    margin-left: 5%;
    transition: color var(--time-transition) ease, transform var(--time-transition) ease;
  }

  & .mainproj-container_date {
    margin-bottom: 3%;
    font-size: 1vw;
    color: var(--text-color);
    margin-left: 5%;
    text-align: left;
    transition: color var(--time-transition) ease, transform var(--time-transition) ease;
  }

  &:hover {
    border-color: var(--hovered-color-links);
    transform: scale(var(--scale-transition));
    .projectCard-container_title{
      transform: scale(var(--scale-transition));
      color: var(--hovered-color-links);
    }
    .mainproj-container_specifics {
      opacity: 1;
    }
    
    .mainproj-container_specifics li{
      border-color: var(--hovered-color-links);
    }
  }
}

.mainproj-container--small{
  width: 21%;
  border-radius: var(--small-border-radius);

  .mainproj-container_title {
    font-weight: bold;
    font-size: 1vw;
    color: var(--text-color);
    text-align: left;
    margin-left: 5%;
    transition: color var(--time-transition) ease, transform var(--time-transition) ease;
  }

  & .mainproj-container_image img{
    border-radius: var(--small-border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & .mainproj-container_specifics ul{
    gap: 0.2vw;
  }

  & .mainproj-container_specifics li{
    font-size: 1vw;
    padding: 0.2vw;
  }
}

.projectCard-description { 
  cursor: default;
  position: fixed; 
  top: 0; 
  width: 100%;
  height: 100%;
  color: var(--text-color);
  overflow-y: auto; 
  display: flex; 
  align-items: flex-start; 
  justify-content: center;
  z-index: 3;
  background-color: var(--description-overlay-color);
  transition: opacity var(--time-project-card-transition) ease;
  opacity: 0;
  
  &.active{
      opacity: 1;
      .content{
        transform: scale(1);
      }
  }

  h1 {
    margin: 0;
    margin-left: var(--margin-project-card);
    margin-right: var(--margin-project-card);
    margin-top: 4vw;
    text-align: left;
    color: var(--hovered-color-links);
  }

  h2{
    margin: 0;
    margin-top: var(--margin-titles);
    margin-bottom: var(--margin-titles);
    font-weight: bolder;
  }

  h3{
    margin: 0;
    margin-top: var(--margin-titles);
    margin-bottom: var(--margin-titles);
    font-weight: lighter;
  }

  h4{
    margin: 0;
    margin-top: var(--margin-small-titles);
    margin-bottom: var(--margin-small-titles);
    font-weight: normal;
  }

  p{
    margin: 0;
  }

  & .mainproj-container_specifics {
    position: relative;
    margin: 0;
    margin-left: var(--margin-project-card);
    margin-right: var(--margin-project-card);
    padding: 0;
    display: flex;
    justify-content: left;
    align-items: right;
    font-size: var(--description-text-size);
    color: var(--text-color);
  }
  
  & .mainproj-container_specifics ul {
    list-style: none;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 0.5vw;
  }

  & .mainproj-container_specifics li {   
    border-style: var(--main-border-style);
    border-color: var(--hovered-color-links);
    border-radius: var(--small-border-radius);
    border-width: var(--main-border-width);
    background: var(--background-color);
    padding: 0.3vw;
  }
  
  .content{
    background-color: var(--background-color);
    z-index: 3;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: var(--margin-project-card);
    margin-right: var(--margin-project-card);
    width: 80%;
    height: auto;
    font-size: 2vw;
    border-style: var(--main-border-style);
    border-color: var(--main-border-color);
    border-radius: var(--main-border-radius);
    border-width: var(--main-border-width);
    box-shadow: var(--box-shadow-default);
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    transition: transform var(--time-project-card-transition) ease;
    transform:scale(0);
    padding-bottom: 4vw;
    
    p{
      font-size: var(--description-text-size);
      text-align: justify;
    }
    
    .button-img{
      position: absolute;
      left: 67vw;
      top: 5vw;
      width: 4%;
      height: auto;
        
      transition: border-color var(--time-transition) ease, box-shadow var(--time-transition) ease, transform var(--time-transition) ease;

      &:hover{
        cursor: pointer;
        transform: scale(var(--scale-transition));

        .img-fill{
          fill: var(--hovered-color-links);
        }
      }

      .img-fill{
        fill: var(--main-border-color);
        transition: fill var(--time-transition) ease;
      }
    }
  }
}

body.no-scroll {
  overflow: hidden;
}

/* == SKILLS SECTION == */


.about-me {
  display: flex; 
  justify-content: center; 
  align-items: center; 

  margin-left: 3%;
  margin-right: 3%;
}

  .about-content {
  display: flex;
  align-items: center;
  text-align: center;
}

.about-image {
  width: auto;
  height: 26vw;
  margin-right: 1vw;
  
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-radius: var(--main-border-radius);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.about-text {
  font-size: var(--about-text-size);
  text-align: justify;
  
  a{
    font-size: var(--about-text-size);
    text-decoration: underline;
    margin-left: 0.3vw;
    margin-right: 0.3vw;
    color: var(--text-color);
    transition: color var(--time-transition) ease;
    font-weight: normal;

    &:hover{
      color: var(--hovered-color-links);
    }
  }
}
/* == SKILLS SECTION == */

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  line-height: 2vw;
  margin-left: var(--margin-text);
  gap: 1vw;

  & .levels{
    width: 100%; 
    margin-left: var(--margin-text);
    font-size: 1.5vw;
    font-weight: semibold;
  }

  & .skills-container {
    background: var(--description-background-color);
    width: 44%;
    color: var(--text-color);
    border-style: var(--main-border-style);
    border-color: var(--main-border-color);
    border-radius: var(--main-border-radius);
    border-width: var(--main-border-width);
    box-shadow: var(--box-shadow-default);
    padding: 1vw;
  }
  & h3 {
    margin: 0;
    font-size: 2vw;
    text-align: left;
    margin-bottom: 0.7vw;
  }

  & li {
    display: flex;
    align-items: center;
  }
  
  & .skill-name
  {
    margin-left: 1vw;
    font-size: 1.5vw;
    font-weight: semibold;
  }
}

/* == CONTACT SECTION == */

.contact {
  height: auto;
  width: 100%;
  margin-left: var(--margin-text);
  margin-right: var(--margin-text);
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  margin-bottom: 6vw;

  & p {
    font-style: var(--quote-font-style);
    font-size: var(--quote-size);
    text-align: left;
  }

  & .contact-socials {
    display: flex;
    gap: 4vw;
    margin-bottom: 5vw;

    & .svg {
      width: 3vw;
      height: auto;
      color: var(--text-color);
      transition: transform var(--time-transition) ease, color var(--time-transition) ease;

      &:hover {
        transform: scale(var(--scale-transition));
        color: var(--hovered-color-links);
      }
    }
  }
}

/* == INDIVIDUAL CARDS CSS == */

.git-img {
  display: block;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 20%;
  height: auto;
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
  transition: transform var(--time-transition) ease, border-color var(--time-transition) ease;

  &:hover{
    transform: scale(var(--scale-transition));
    border-color: var(--hovered-color-links);
  }
}


.itch-img {
  display: block;
  margin: 0 auto;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 20%;
  height: auto;
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
  transition: transform var(--time-transition) ease, border-color var(--time-transition) ease;
  &:hover{
    transform: scale(var(--scale-transition));
    border-color: var(--hovered-color-links);
  }
}

.Trailer {
  margin-top: 2%;
  cursor: pointer;
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.img-1 {
  width: 31%;
  margin-top: 3%;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.img-2 {
  width: 31%;
  margin-left: 2.5%;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.img-3 {
  width: 31%;
  margin-left: 2.5%;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}