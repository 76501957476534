.Raymarcher {
    margin-left: var(--margin-project-card);
    margin-right: var(--margin-project-card);
}

.video-container {
  display: flex;
  align-items: left;
  gap: 3vw;
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
}

.video-container-left {
  flex-direction: row;
}

.video-container-left .video-description{
  margin-top: 2%;
}

.video-container-right .video-description{
  margin-top: 2%;
}

.video-container-right {
  flex-direction: row-reverse; 
}

.Raymarcher .SphereBox{
  width: 31%;
  height: auto;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.Raymarcher .Fractals{
  width: 31%;
  height: auto;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.Raymarcher .FractalsRotated{
  width: 31%;
  height: auto;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.Raymarcher .SphereBoxShow{
  margin-top: 1%;
  width: 31%;
  height: auto;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.Raymarcher .FractalsShow{
  margin-left: 2.5%;
  width: 31%;
  height: auto;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}

.Raymarcher .FractalsRotatedShow{
  margin-left: 2.5%;
  width: 31%;
  height: auto;
  
  border-radius: var(--main-border-radius);
  border-style: var(--main-border-style);
  border-color: var(--main-border-color);
  border-width: var(--main-border-width);
  box-shadow: var(--box-shadow-default);
}