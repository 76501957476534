.Tectonic{
    margin-left: var(--margin-project-card);
    margin-right: var(--margin-project-card);
}

.Tectonic .big{
    margin-top: 2%;
    width: 50%;
    height:auto;

    border-radius: var(--main-border-radius);
    border-style: var(--main-border-style);
    border-color: var(--main-border-color);
    border-width: var(--main-border-width);
    box-shadow: var(--box-shadow-default);

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.Tectonic .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: center;
    gap: 2vw ;
}

.Tectonic .column{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Tectonic .img{
    width: 100%;
    border-radius: var(--main-border-radius);
    border-style: var(--main-border-style);
    border-color: var(--main-border-color);
    border-width: var(--main-border-width);
    box-shadow: var(--box-shadow-default);
    margin-bottom: 3%;
    margin-top: 2%;
}